<template>
  <div class="scoreDetail">
    <!-- 顶部导航 -->
    <!-- <van-nav-bar class="topnav" title="原创文章" left-arrow @click-left="toBack" :border="false" /> -->
    <div class="scoreCont">
      <h1 class="title">1.如何获取积分？</h1>
      <p>在任务列表浏览文章，转发到朋友圈和社群即为领取任务。每有一名好友通过朋友圈链接和社群链接打开文章可积一分。</p>
      <div class="mt"></div>
      <h1 class="title">2.积分有什么用</h1>
      <p>个人积分可在积分商城兑换相应奖品；</p>
      <p>根据积分排名，月度、季度、年度上榜的发声人可获得额外奖励如积分、奖品、官方证书等。</p>
    </div>
    <!-- 底部导航 -->
    <!-- <index-footer></index-footer> -->
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import indexFooter from "./footer.vue";
import Cookies from "js-cookie";
import { Notify } from "vant";
export default {
  data() {
    return {
      loadNum: 0,
      loadShow: false,
    };
  },
  components: {
    indexFooter
  },
  created() {
  },
  methods: {
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    toBack() {
      this.$router.go(-1);
    }
  }
};
</script>
<style scoped>
.scoreDetail {
  width: 100%;
  min-height: 100vh;
  padding: 0.52rem 0.4rem;
  padding-bottom: 1.5rem;
}
.scoreDetail .mt{
  height: 0.3rem;
}
.scoreDetail .scoreCont .title{
  font-size: 0.45rem;
  line-height: 0.45rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  padding-left: 0.2rem;
  position: relative;
}
.scoreDetail .scoreCont .title:after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background: #004eb4;
}
.scoreDetail .scoreCont p{
  font-size: 0.4rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666;
  padding-left: 0.2rem;
  position: relative;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
}
.scoreDetail .scoreCont p:after{
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #666;
  position: absolute;
  top: 0.25rem;
  left: 0;
}
</style>