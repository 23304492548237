/**
 * 文章 接口的统一出口
 */
 import axios from '../http' // 导入http中创建的axios实例
 import qs from 'qs' // 根据需求是否导入qs模块，把请求字段直接映射过来
 
 const api = '/api/'
 
 const index = {
   // 列表
   list (params) {
     return axios.get(api + 'article/list', { params: params })
   },
   // 文章详情
   detail (params) {
     return axios.get(api + 'article/detail', { params: params })
   },
   // 发布文章
   create (params) {
    return axios.post(api + 'article/create', qs.stringify(params))
  },
  // 被驳回重新发布
  edit (params) {
    return axios.post(api + 'article/edit', qs.stringify(params))
  },
  // 我的原创文章详情
  myDetail (params) {
    return axios.get(api + 'article/myDetail', { params: params })
  },
  // 我的转发文章详情
  zhuanDetail (params) {
    return axios.get(api + 'article/zhuanDetail', { params: params })
  },
  // 分享的文章详情
  shareDetail (params) {
    return axios.get(api + 'article/shareDetail', { params: params })
  },
  // 我的原创 - 任务列表
  taskList (params) {
    return axios.get(api + 'article/task', { params: params })
  },
  // 任务列表 点击按钮领取积分
  score (params) {
    return axios.post(api + 'article/score', qs.stringify(params))
  },
 }
 
 // 导出接口
 export default index
 