<template>
  <div class="mYrenwu">
    <!-- 顶部导航 -->
    <div class="scoreCont">
      <van-nav-bar
        title
        left-text
        left-arrow
        :fixed="false"
        :border="false"
        :safe-area-inset-top="true"
        @click-left="onClickLeft"
      />
      <div class="topBack">
        <img src="../../public/statics/images/my/top1.png" alt />
      </div>
      <div class="content">
        <div class="centerNav">
          <div class="left">
            <span class="num">{{myData.today_score}}</span>
            <span>今日完成</span>
          </div>
          <div>
            <span class="num">{{myData.total_score}}</span>
            <span>累计完成</span>
          </div>
        </div>
        <div class="desc">
          <span>当前区域任务还有{{myData.duty_num}}条，可多领{{myData.score}}积分</span>
        </div>
        <div class="submitHold">
          <van-button
            round
            plain
            :loading="submitLoading"
            class="submit"
            type="info"
            @click="toPath('/renwuList',{})"
          >立即转发</van-button>
        </div>
      </div>
      <!-- <h1 class="title">我的任务</h1>
      <div class="mt"></div>
      <p>
        今日完成
        <span>{{myData.today_score}}</span>
      </p>
      <p>
        累计完成
        <span>{{myData.total_score}}</span>
      </p>
      <p>
        当前区域任务还有
        <span>{{myData.duty_num}}</span> 条，可多领
        <span>{{myData.score}}</span> 分
      </p> 
      <div class="mt"></div>
      <div class="submitHold">
        <van-button
          round
          plain
          :loading="submitLoading"
          class="submit"
          type="info"
          @click="toPath('/renwuList',{})"
        >立即转发</van-button>
      </div>-->
    </div>
    <!-- 加载 -->
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import indexFooter from "./footer.vue";
import Cookies from "js-cookie";
import { Notify } from "vant";
export default {
  setup() {
    const onClickLeft = function() {
      history.back();
    };
    return {
      onClickLeft
    };
  },
  data() {
    return {
      loadNum: 0,
      loadShow: false,
      submitLoading: false,
      myData: ""
    };
  },
  components: {
    indexFooter
  },
  created() {
    this.loadNum++;
    this.loadShow = true;
    this.getData();
  },
  methods: {
    getData() {
      let that = this;
      let ps = {
        token: Cookies.get("spToken")
      };
      that.$api.my.dutyMy(ps).then(function(response) {
        if (response.code === 200) {
          that.loadNum--;
          if (that.loadNum == 0) {
            that.loadShow = false;
          }
          that.myData = response.result;
        }
      });
    },
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    toPath(url, obj) {
      if (this.$route.fullPath == url) {
        return;
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        });
        window.location.reload();
        return;
      }
      this.$router.push({
        path: url,
        query: obj
      });
    },
    toBack() {
      this.$router.go(-1);
    }
  }
};
</script>
<style scoped>
.mYrenwu .van-nav-bar{
  position: fixed;
}
.mYrenwu .van-nav-bar /deep/.van-icon{
  color: #fff;
  font-size: 0.6rem;
}
.mYrenwu {
  width: 100%;
  min-height: 100vh;
  /* padding: 0.52rem 0.4rem; */
  /* padding-bottom: 1.5rem; */
}
.mYrenwu .content {
  position: relative;
  top: -1.7rem;
}
.mYrenwu .content .centerNav {
  margin: 0 0.4rem;
  background: #fff;
  box-shadow: 0rem 0.13rem 0.47rem 0rem rgba(50, 93, 205, 0.09);
  z-index: 10;
  height: 2.55rem;
  border-radius: 0.07rem;
  display: flex;
  align-items: center;
}
.mYrenwu .content .centerNav .left {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.mYrenwu .content .centerNav div {
  width: 50%;
  height: 0.57rem;
}
.mYrenwu .content .centerNav div span {
  display: block;
  text-align: center;
  font-size: 0.4rem;
  color: #666;
  margin-top: -0.4rem;
}
.mYrenwu .content .centerNav .num {
  font-size: 0.53rem;
  color: #0c47ad;
  margin-bottom: 0.4rem;
  font-weight: bold;
}
.mYrenwu .content .desc {
  text-align: center;
  margin-top: 0.5rem;
}
.mYrenwu .content .desc span {
  font-size: 0.43rem;
  color: #0c47ad;
}
.mYrenwu .mt {
  height: 0.3rem;
}
.mYrenwu .scoreCont .title {
  font-size: 0.45rem;
  line-height: 0.45rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  padding-left: 0.2rem;
  position: relative;
}
.mYrenwu .scoreCont .title:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background: #004eb4;
}
.mYrenwu .scoreCont p {
  font-size: 0.4rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #444;
  padding-left: 0.2rem;
  position: relative;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
}
.mYrenwu .scoreCont p span {
  font-size: 0.5rem;
  color: rgb(11, 71, 172);
}
.mYrenwu .scoreCont p:after {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #333;
  position: absolute;
  top: 0.25rem;
  left: 0;
}
.mYrenwu .submitHold {
  width: 6rem;
  margin: 0 auto;
  margin-top: 1.5rem;
}
.mYrenwu .submitHold .submit {
  width: 100%;
  border: none;
  background: #0b47ac;
  font-size: 0.45rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
</style>